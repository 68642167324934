


































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { EFileError, EFileFormat, IdName } from '@/models'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import GroupSharedFolderService from '@/services/GroupSharedFolderService'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
import updateGroupMaxFileSize from '@/helpers/UpdateGroupMaxFileSize'

/**
 * f2-605
 * サークル共有フォルダ ファイル登録
 */
@Component
export default class FileCreate extends Vue {
  private folderOptions: IDropDown[] = []
  private folderSelected: any = {}
  private fileChoose: any
  private title: string = ''
  private comment: string = ''
  private chooseFileName: string = String(this.$t('common.form.not_selected'))
  private displayChooseFile: string = ''
  private eFileFormat = EFileFormat
  private ehumbNailsDisplay = EhumbNailsDisplay
  private fileErrors: EFileError = EFileError.NONE
  private eFileError = EFileError
  private maxFileSize: any = {
    mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
    bytes:
      Number(this.$store.state.userInfo.user.current_group_max_file_size) *
      1048576
  }
  private confirmLeave: any = () => {}
  private confirmLeaveIgnore: boolean = false
  private modalMess: string = ''

  created() {
    this.initFolder()
    this.handleMaxFileSize()
  }

  async handleMaxFileSize() {
    await updateGroupMaxFileSize(this.$route.params.groupId)
    this.maxFileSize = {
      mb: Number(this.$store.state.userInfo.user.current_group_max_file_size),
      bytes:
        Number(this.$store.state.userInfo.user.current_group_max_file_size) *
        1048576
    }
  }

  checkRouterBeforLeave() {
    return !this.title && !this.comment && !this.fileChoose
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (!this.confirmLeaveIgnore && !this.checkRouterBeforLeave()) {
      this.$bvModal.show('file-create-modal-confirm-leave')
      this.confirmLeave = next
    } else {
      next()
    }
  }

  initFolder() {
    GroupSharedFolderService.getAllFolderOfSharedFolder(
      this.$route.params.groupId
    )
      .then(res => {
        if (res.status === 200) {
          const defaultOption = {
            id: 0,
            value: undefined,
            text: this.$t('groups.unclassified') as string
          }
          const folders = res.data.map((item: IdName, index: number) => {
            return {
              id: index + 1,
              value: item,
              text: item.name
            }
          })
          const mergeDatas = [defaultOption, ...folders]
          this.folderOptions = mergeDatas
          if (mergeDatas.length) {
            const folderId = this.$route.params.folderId
            const findFolder = mergeDatas.find(
              item => item.value?.id == folderId
            )
            this.folderSelected = findFolder ? findFolder : mergeDatas[0]
          }
        }
      })
      .catch(err => {
        this.modalMess = this.$t('common.message.uncatch_error') as string
        this.$bvModal.show('modal-error-create-file')
      })
  }

  handleSelectFolder(index: number) {
    this.folderSelected = this.folderOptions[index]
  }

  onFileChange(event: any) {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return
    if (files[0].size > this.maxFileSize.bytes) {
      this.fileErrors = EFileError.OVER_SIZE
      this.modalMess = this.$t('groups.shared_folder.overwrite_error_msg', {
        max: this.maxFileSize.mb
      }) as string
      this.$bvModal.show('modal-info-create-file')
      return
    }
    this.fileErrors = EFileError.NONE
    this.fileChoose = files[0]
    this.chooseFileName = files[0].name
    this.displayChooseFile = URL.createObjectURL(files[0])
  }

  /**
   * Get file format by file extension
   */
  getFileFormat() {
    return CheckFileFormat.getFileFormatWithFileName(this.chooseFileName)
  }

  /**
   * Get fontawsome icon by file extension
   */
  getIconName() {
    return CheckFileFormat.getIconClassNameWithFileName(this.chooseFileName)
  }

  submitCreateFile() {
    if (this.fileChoose) {
      let formData = new FormData()
      formData.set('groupId', this.$route.params.groupId)
      formData.set('file', this.fileChoose)
      formData.set('title', this.title)
      formData.set('memo', this.comment)
      if (this.folderSelected.value) {
        formData.set('folder_id', this.folderSelected.value.id)
      }
      this.$blockui.show()
      GroupSharedFolderService.createNewFile(formData)
        .then(res => {
          if (res.status === 201) {
            this.confirmLeaveIgnore = true
            this.fileErrors = EFileError.NONE
            this.$bvModal.show('modal-success-create-file')
          }
        })
        .catch(err => {
          if (err.response.data.message === 'EXCEED_GROUP_STORAGE_CAPACITY') {
            this.modalMess = this.$t('common.message.group_over_size') as string
          } else {
            this.modalMess = this.$t('common.message.register_fail') as string
          }
          this.$bvModal.show('modal-error-create-file')
        })
        .finally(() => this.$blockui.hide())
    } else {
      this.fileErrors = EFileError.EMPTY
    }
  }

  jumpSharedFolderList() {
    this.$router.push({ name: 'group-shared-folder' })
  }

  routeSharedFolderList() {
    return ({ name: 'group-shared-folder' })
  }
}

interface IDropDown {
  id: number
  value: any
  text: string
}
